@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Playfair+Display:wght@400;600&display=swap');
@import 'antd/dist/antd.css';


.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  color: white;
  background-color: #20242B;
}

.layout{
  background-color: #20242B;
  min-height: 100vh;
  display: flex;
  justify-content: space-around;
  margin: 50px 0;
  padding: 5px;
}

.logo{
  position: fixed; 
  left : 20px; 
  top : 20px;
  z-index: 1;
}

h1{
  font-family: 'Playfair Display', sans-serif;
  font-size: 100px;
  font-weight: bold;
  background: linear-gradient(to right, rgba(194,44,72,1) 75%, rgb(244, 115, 29) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-duration: 2s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

h2{
  font-family: 'Playfair Display', sans-serif;
  font-size: 55px;
  font-weight: 400;
  color: white;
  position: relative;
  bottom: 80px;
  animation-duration: 3s;
  animation-name: slidein
}

h3{
  font-family: 'Playfair Display', serif;
  font-size: 60px;
  font-weight: bold;
  background: linear-gradient(to right, rgba(194,44,72,1) 50%, rgb(244, 115, 29) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  margin: 50px 0;
}

h4{
  color: #343942;
  font-family: 'Playfair Display', sans-serif;
  font-size: 60px;;
}

.home{
  background-image: url(../public/images/bgClean.png);
  background-size: cover;
  margin: 0 0 50px 0;
}

.socialResHome{
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 20px;
}

.textHome{
  position: absolute;
  top: 40%;
  left: 20%;
  transform: translate(-20%, -40%);
}

.icon2{
  margin: 10px;
  font-size: 22px;
  cursor: pointer;
  color: white;
}

.icon2:hover{
  color: #C22C48;
}


.squareAboutMe{
  width: 300px;
  height: 370px;
  border: 5px solid #C22C48;
}

.photoOfMe{
  width: 300px;
  position: relative;
  right: 40px;
  top: 30px;
}

.itemAboutMe{
  max-width: 90%;
}

.ant-timeline-item-tail{
  border-color: #C22C48;
}

.ant-timeline-item-head{
  width: 15px;
  height: 15px;
  background-color: #C22C48;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-head{
  margin-left: -6px;
}

.itemTimeline{
  color: white;
  width: 120px;
  height: 120px;
  text-align: center;
  background-color: #343942;
  border-radius: 50%;
  padding: 28px;
  margin: 15px 15px 0 15px;
}

.itemTimeline:hover{
  color: #343942;
  background-color: #C22C48;
}
.skills{
  background-color: #343942;
  text-align: start;
  padding: 50px;
}

.progressBar{
  position: relative;
  bottom: 15px;
}

.textResponsive{
  margin-top: 80px;
  font-size: 16px;
  font-style: italic
}

.squareSkills, .squareSkills2{
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  width: 100%;
}

.squareSkills{
  background-color: #343942;
  height: 300px;
}

.squareSkills2{
  background-color: #C22C48;
  height: 320px;
}

.titleSquareSkills1{
  color: #C22C48;
}

.titleSquareSkills2{
  color: #20242B;
}

.titleSquareSkills1, .titleSquareSkills2{
  font-size: 50px;
  font-weight: bold;
  font-family: 'Playfair Display', sans-serif;
  margin: 0;
}

.boxProject{
  height: 240px;
  width: 350px;
  position: relative;
  margin: 15px;
}

.overlay{
  transition: .5s ease;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  border: 8px solid #20242a;
  overflow: hidden;
  transform: scale(0);
  transition: .3s linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.annoucement{
  background-color: #F4731D;
  padding: 20px;
  color: #20232B;
  margin: 0;
}

.textProject{
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  padding: 20px;
}

.boxProject:hover .overlay{
  transform: scale(1);
}

.imgProjet{
  display: block;
  width: 100%;
  height: 100%;
}

.formContact{
  width: 650px;
  max-width: 100%;
  color: white;
}

.notification{
  background-color: #C22C48;
  color: white;
}

.ant-input{
  height: 60px;
  background-color: #20242B;
  border: 0;
  border-bottom: 1px solid #C22C48;
  color: white;
}

.ant-input:hover{
  border: 0;
  border-bottom: 1px solid rgb(244, 115, 29);
}

.ant-input:focus, .ant-input-focused {
  border-color: rgb(244, 115, 29);
  box-shadow: 0 0 0 0;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover{
  background-color: #20242B;
}

.button1{
  width: 200px;
  height: 50px;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to right, #C22C48 90%, #F4731D 100%)1;
  color: #C22C48;
  font-weight: bold;
  margin: 30px;
  cursor: pointer;
}

.button2{
  width: 200px;
  height: 50px;
  background-color: #20232B;
  border-width: 1px;
  border-style: solid;
  border-color: #C22C48;
  color: #F4731D;
  font-weight: bold;
  cursor: pointer;
}

.button1:hover{
  background-color: #C22C48;  
  color: #20242B;
  box-shadow: 0 1px 20px rgba(194, 44, 72, 0.80);
}

.button2:hover{
  background-color: #C22C48;  
  color: #20242B;
  box-shadow: 0 1px 20px rgba(194, 44, 72, 0.80);
}

.footer{
  padding: 40px;
  height: 350px;
  background-color: #20242B;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.icon1{
  font-size: 25px;
  padding: 10px;
  color: white;
  border-radius: 50%;
  background-color: #343942;
  margin: 10px;;
}

.icon1:hover{
  color: #343942;
  background-color: #C22C48;
}